.header-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  //background-color: $white;
  z-index: 3;

  @include breakpoint(large) {
    position: absolute;
  }
}

.logo-wrapper {
  position: fixed;
  width: 100%;
  right: 0;
  top: rem-calc(9);
  z-index: 5;

  @include breakpoint(xlarge) {
    top: rem-calc(20);
  }

  @include breakpoint(xxxlarge) {
    top: rem-calc(30);
  }
}

.logo {
  background-image: url(../images/logo.svg), none;
  background-repeat: no-repeat;
  background-size: 100% 100%, 31px 42px;
  background-position: right top, calc(100% - 7px) 7px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: rem-calc(180);
  height: rem-calc(55);

  @include breakpoint(large) {
    background-size: 100% 100%, 40px 54px;
    background-position: right top, calc(100% - 9px) 8px;
    width: rem-calc(240);
    height: rem-calc(73);
  }

  @include breakpoint(xxxlarge) {
    background-size: 100% 100%, 50px 67px;
    background-position: right top, calc(100% - 12px) 10px;
    width: rem-calc(300);
    height: rem-calc(90);
  }

  @include breakpoint(xxxxlarge) {
    background-size: 100% 100%, 64px 86px;
    background-position: right top, calc(100% - 14px) 14px;
    width: rem-calc(379);
    height: rem-calc(115);
  }
  
  a {
    display: block;
    height: 100%;
  }
}

.logo-wrapper.headroom--not-top {
  z-index: 5;
  opacity: 0;
  animation-name: change;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  .logo {
    background-image: none, url(../images/logo-small-1.png);
    background-size: 100% 100%, 31px 42px;
    background-position: right top, calc(100% - 7px) 7px;

    @include breakpoint(large) {
      background-size: 100% 100%, 40px 54px;
      background-position: right top, calc(100% - 9px) 8px;
    }

    @include breakpoint(xxxlarge) {
      background-size: 100% 100%, 50px 67px;
      background-position: right top, calc(100% - 12px) 10px;
    }

    @include breakpoint(xxxxlarge) {
      background-size: 100% 100%, 64px 86px;
      background-position: right top, calc(100% - 14px) 14px;
    }

    &:hover {
      @include breakpoint(large) {
        background-image: none, url(../images/logo-ani-medium.gif);
      }

      @include breakpoint(xxxlarge) {
        background-image: none, url(../images/logo-ani-large.gif);
      }

      @include breakpoint(xxxxlarge) {
        background-image: none, url(../images/logo-ani-xlarge.gif);
      }
    }
  }
}

@keyframes change {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.header-spacing {
  @include breakpoint(large) {
    height: rem-calc(78);
  }
}

.headroom {
  will-change: transform;
  transition: transform 0.1s linear;
}

header .header-nav {
  pointer-events: none;

  @include breakpoint(large) {
    pointer-events: all;
  }

  ul {
    display: none !important;

    @include breakpoint(large) {
      display: flex !important;
    }
  }
}

.menu-open header .header-nav {
  pointer-events: all;
  display: block;

  ul {
    display: flex !important;
  }
}

body .header-wrapper.headroom--not-top {
  @include breakpoint(large) {
    position: fixed;
    width: 100%;
    top: 0 !important;
    //transform: translateY(-50%);
  }
}
