@mixin swiss(){
  font-family: 'Swiss721BT-Bold', Arial, sans-serif;
  font-weight: normal;
}

@mixin roboto(){
  font-family: 'robotoregular', Arial, sans-serif;
  font-weight: normal;
}

@mixin h1() {
  @include swiss;
  font-size: rem-calc(22);
  line-height: rem-calc(22);

  @include breakpoint(large) {
    font-size: rem-calc(36);
    line-height: rem-calc(36);
  }

  @include breakpoint(xxlarge) {
    font-size: rem-calc(46);
    line-height: rem-calc(46);
  }

  @include breakpoint(xxxlarge) {
    font-size: rem-calc(52);
    line-height: rem-calc(52);
  }

  @include breakpoint(xxxxlarge) {
    font-size: rem-calc(56);
    line-height: rem-calc(56);
  }
}

@mixin h2() {
  @include h1;
}

@mixin h3() {
  @include swiss;
  font-size: rem-calc(16);
  line-height: rem-calc(16);

  @include breakpoint(large) {
    font-size: rem-calc(24);
    line-height: rem-calc(24);
  }

  @include breakpoint(xxlarge) {
    font-size: rem-calc(34);
    line-height: rem-calc(34);
  }
}

@mixin p() {
  @include roboto();
  font-size: rem-calc(13);
  line-height: rem-calc(18);

  @include breakpoint(large) {
    font-size: rem-calc(16);
    line-height: rem-calc(21);
  }

  @include breakpoint(xxlarge) {
    font-size: rem-calc(17);
    line-height: rem-calc(22);
  }

  //@include breakpoint(xxxxlarge) {
  //  font-size: rem-calc(18);
  //  line-height: rem-calc(24);
  //}
}

@mixin p_bold() {
  @include swiss();
  font-size: rem-calc(17);
  line-height: rem-calc(21);

  @include breakpoint(large) {
    font-size: rem-calc(19);
    line-height: rem-calc(25);
  }

  @include breakpoint(xlarge) {
    font-size: rem-calc(20);
    line-height: rem-calc(26);
  }

    @include breakpoint(xxlarge) {
    font-size: rem-calc(24);
    line-height: rem-calc(30);
  }
}

@mixin with-border() {
  &::before {
    content: '';
    background-color: $black;
    display: block;
    height: rem-calc(5);
    width: calc(100% + 10px);
    margin-left: rem-calc(-10);
    margin-bottom: rem-calc(10);

    @include breakpoint(large) {
      /*width: 50%;*/
      height: rem-calc(8);
      margin-left: 0;
    }

    @include breakpoint(xlarge) {
      width: percentage(550px / 690px);
      // height: rem-calc(8);
      margin-left: 0;
      margin-bottom: rem-calc(20);
    }

  }
}

@mixin hr() {
  background-color: $black;
  height: rem-calc(5);
  border: none;
  right: 0;
  z-index: 2;

  @include breakpoint(large) {
    height: rem-calc(8);
  }
}

@mixin line-after() {
  &::after {
    display: block;
    content: '';
    width: rem-calc(50);
    height: rem-calc(5);
    background-color: $white;
    margin: rem-calc(12) auto rem-calc(30) auto;

    @include breakpoint(xxlarge) {
      margin: rem-calc(32) auto rem-calc(60) auto;
      width: rem-calc(100);
      height: rem-calc(8);
    }
  }
}

@mixin button(){
  display: inline-block;
  vertical-align: middle;
  padding: rem-calc(8) rem-calc(16);
  appearance: none;
  border: 3px solid $black;
  border-radius: 0;
  transition: background-color 0.25s ease-out,color 0.25s ease-out;
  @include swiss;
  text-decoration: none;
  font-size: rem-calc(13);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: $black;

  @include breakpoint(large) {
    font-size: rem-calc(15);
  }

    @include breakpoint(xlarge) {
    font-size: rem-calc(18);
  }

  @include breakpoint(xxlarge) {
    border: 4px solid $black;
    font-size: rem-calc(22);
    padding: rem-calc(10) rem-calc(24);
  }


  &:hover {
    background-color: $black;
    color: $white;
  }
}
