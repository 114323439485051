.team-heading {
  margin-top: rem-calc(50);
  padding-top: rem-calc(25);
  padding-bottom: rem-calc(25);
  background-color: $light-gray;

  @include breakpoint(xlarge) {
    margin-top: rem-calc(100);
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(190);
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(100);
  }

  h2 {
    margin: 0;

    @include breakpoint(large) {
      max-width: 60%;
    }

    @include breakpoint(xlarge) {
      max-width: 40%;
    }
  }

  p {
    @include p_bold();
    margin: rem-calc(16) 0 0 0;

    @include breakpoint(xlarge) {
       margin: rem-calc(24) 0 -9px 0;
    }
  }

  .columns {
    display: flex;
    align-items: flex-end;
    flex-flow: row wrap;
  }

  hr {
    width: 100%;
    height: 5px;
    background-color: $black;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;

    @include breakpoint(large) {
      height: 8px;
    }
  }

}

.team-heading + .team-member {
  margin-top: -10px;

  @include breakpoint(xlarge) {
    margin-top: -40px;
  }

  @include breakpoint(xxlarge) {
     margin-top: -80px;
  }
}

.team-member {
  background-color: $light-gray;
  padding-bottom: rem-calc(25);

  @include breakpoint(xlarge) {
    padding-bottom: rem-calc(50);
  }

  @include breakpoint(xxlarge) {
    padding-bottom: rem-calc(100);
  }

  figure img {
    width: 100%;
  }


  h3 {
    @include p_bold();
    margin: 0;
    margin-top: rem-calc(12);

    @include breakpoint(xlarge) {
      margin-top: rem-calc(20);
    }
  }

  p {
    margin: 0;

    a {
      color: $black;
      text-decoration: none;
    }

    &:nth-child(3) {
      @include line-after;
    }

    &:nth-child(3)::after {
      margin: 0;
      background-color: $black;
      margin: rem-calc(3) 0 rem-calc(5) 0;

      @include breakpoint(large) {
        margin: rem-calc(4) 0 rem-calc(8) 0;
      }
    }

    &:last-child {
      margin-bottom: rem-calc(16);

      @include breakpoint(xlarge) {
        margin-bottom: rem-calc(25);
      }
    }
  }
}