.swiper-container {
  width: 100%;
  height: auto;

  .swiper-slide {
    display: flex;
    flex-flow: row wrap;

    article, figure img {
      width: 100%;
    }
  }

}

.swiper-container-horizontal > .swiper-pagination {
  text-align: left;

  @include breakpoint(large) {
    margin-left: 2.605%;
    bottom: rem-calc(28);
    top: inherit !important;
    left: 0 !important;
  }

  @include breakpoint(xxlarge) {
    bottom: rem-calc(38);
  }

  .swiper-pagination-bullet {
    height: rem-calc(4);
    width: rem-calc(30);
    border-radius: 0;

    @include breakpoint(large) {
      width: rem-calc(50);
    }

    &-active {
      background-color: $black;
    }
  }
}

.slider-split {
  figure {
    margin: 0;
    padding: 0;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;

    &:first-child {
      order: 2;

      @include breakpoint(large) {
        order: 1;
      }
    }

    &:last-child {
      order: 1;

      @include breakpoint(large) {
        order: 2;
      }
    }

    @include breakpoint(large) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &.black figcaption {
      border-color: $black;
      color: $black;
    }
    
    figcaption {
      color: $white;
      position: absolute;
      top: 0;
      left: 0;
      font-size: rem-calc(13);
      border-bottom: 6px solid $white;
      display: inline-block;
      padding-left: rem-calc(16);
      padding-top: rem-calc(20);

      @include breakpoint(large) {
        font-size: rem-calc(17);
        border-bottom: 7px solid $white;
        padding-left: rem-calc(32);
        padding-top: rem-calc(35);
      }

      @include breakpoint(xxlarge) {
        border-bottom: 8px solid $white;
        padding-left: rem-calc(42);
      }

    }

    .description {
      position: absolute;
      bottom: rem-calc(30);
      left: 0;
      max-width: 70%;
      border-top: 6px solid $black;
      padding-left: rem-calc(16);

      @include breakpoint(large) {
        border-top: 7px solid $black;
        bottom: rem-calc(32);
        max-width: 65%;
        padding-left: rem-calc(32);
      }

      @include breakpoint(xxlarge) {
        border-top: 8px solid $black;
        bottom: rem-calc(48);
        padding-left: rem-calc(50);
      }

    }

    h2 {
      @include swiss;
      font-size: rem-calc(28);
      line-height: rem-calc(28);
      margin: rem-calc(10) 0 rem-calc(14) 0;

      @include breakpoint(large) {
        font-size: rem-calc(32);
        line-height: rem-calc(32);
        margin: rem-calc(12) 0 rem-calc(24) 0;
      }

      @include breakpoint(xxlarge) {
        font-size: rem-calc(60);
        line-height: rem-calc(60);
        margin: rem-calc(12) 0 rem-calc(24) 0;
      }

      @include breakpoint(xxxxlarge) {
        font-size: rem-calc(80);
        line-height: rem-calc(80);
        margin: rem-calc(18) 0 rem-calc(40) 0;
        max-width: 84%;
      }
    }

    &.white .description,
    &.white .description a {
      border-color: $white;
      color: $white;
    }

  }

  .swiper-slide[data-swiper-slide-index="0"] .description h2 {
    @include breakpoint(xxxxlarge) {
      max-width: 90%;
    }
  }
}

.swiper-slide.white {
  h1 {
    color: $white;

    &::before {
      background-color: $white;
    }
  }
}


.slide-headline {
  margin-top: rem-calc(50);

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(100);
  }

  article {
    position: relative;
  }

  h1 {
    width: 80%;
    padding-left: rem-calc(32);
    position: absolute;
    z-index: 1;

    @include breakpoint(large) {
      width: percentage(750/1920);
      padding-left: rem-calc(50);
    }

    @include breakpoint(xxlarge) {
      width: percentage(630/1920);
      padding-left: rem-calc(100);
    }

    @include breakpoint(xxxlarge) {
      width: percentage(570/1920);
      padding-left: rem-calc(100);
    }

    @include breakpoint(xxxxlarge) {
      width: percentage(500/1920);
    }

    &::before {
      width: calc(100% + 32px);
      margin-left: rem-calc(-32);

      @include breakpoint(large) {
        width: calc(100% + 50px);
        margin-left: rem-calc(-50);
      }

      @include breakpoint(xxlarge) {
        width: calc(100% + 100px);
        margin-left: rem-calc(-100);
      }
    }
  }
}

.slide-text {
  margin-top: rem-calc(50);
  display: flex;
  flex-flow: row wrap;

   @include breakpoint(large) {
     display: block;
   }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(100);
  }

  article {
    position: relative;
  }

  .swiper-wrapper {
    order: 1;
  }

  .swiper-pagination-bullets {
    top: rem-calc(8);
    left: 10px;
  }

  .text {
    bottom: 0;
    right: rem-calc(16);
    padding: 0 rem-calc(16) rem-calc(4) rem-calc(16);
    z-index: 3;
    order: 2;
    background-color: $light-gray;

    @include breakpoint(large) {
      background-color: transparentize($white, 0.5);
      border-top: rem-calc(5) solid $black;
      max-width: 75%;
      position: absolute;
      border-top: rem-calc(8) solid $black;
      max-width: 100%;
      width: percentage(900/1920);
      right: percentage(120/1920);
      padding: 0 rem-calc(20) rem-calc(8) rem-calc(20);
    }

    @include breakpoint(xxlarge) {
      width: percentage(580/1920);
      right: percentage(177/1920);
      padding: 0 rem-calc(32) rem-calc(12) rem-calc(32);
    }
  }

  h3 + p {
    margin-top: rem-calc(-8);

    @include breakpoint(large) {
      margin-top: rem-calc(-8);
    }

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(-16);
    }
  }
}

.gallery-carousel {
  padding-top: rem-calc(80);

  @include breakpoint(large) {
    padding-top: rem-calc(100);
  }

  @include breakpoint(xxlarge) {
    padding-top: rem-calc(190);
  }

  &.white {
    color: $white;

    h2::before {
      background-color: $white;
    }
  }

  h2 {
    position: absolute;
    z-index: 2;
    padding-left: rem-calc(10);
    top: 0;
    width: 90%;

    @include breakpoint(large) {
      top: 14.5%;
      margin: 0;      
      width: 50%;
      padding-left: 20%;
    }

    @include breakpoint(xxlarge) {
      top: 14%;
      width: 49%;
      padding-left: 20%;
    }

    @include breakpoint(xxxlarge) {
      top: 11%;
      width: 51%;
      padding-left: 20%;
    }

    &::before {
      @include breakpoint(large) {
        position: absolute;
        left: 0%;
        top: rem-calc(16);
        margin: 0;
        width: 35%;
      }

      @include breakpoint(xxlarge) {
        top: rem-calc(34);
        width: 39%;
      }

      @include breakpoint(xxxlarge) {
        top: rem-calc(34);
        width: 38%;
      }
    }
  }

  .swiper-slide {
    width: 100%;

    @include breakpoint(large) {
      width: 25%;
    }

    &.large {
      width: 100%;

      @include breakpoint(large) {
        width: 50%;
      }
    }
  }

  .swiper-slide figure,
  .swiper-slide figure img {
    width: 100%;
  }
}

.gallery {
  margin-top: rem-calc(50);
  position: relative;

  @include breakpoint(large) {
    margin-top: rem-calc(100);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(190);
  }

  figure,
  figure img {
    width: 100%;
  }

  h2 {
    text-align: center;

    em {
      color: $medium-gray;
      font-style: normal;
    }

    &::after {
      background-color: $black;
    }
  }

  .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    pointer-events: all;
    cursor: pointer;
  }

  .swiper-button-next, .swiper-button-prev {
    top: 100%;
    margin-top: rem-calc(3);

    //transform: translateY(-50%);
    @include breakpoint(large) {
      top: 50%;
      margin: 0;
    }
  }

  .swiper-button-next-gallery {
    right: 32px;

    @include breakpoint(xxxlarge) {
      right: -32px;
    }
  }

  .swiper-button-prev-gallery {
    left: 32px;

    @include breakpoint(xxxlarge) {
      left: -32px;
    }
  }

}

.gallery-thumbs {
  display: none;

  @include breakpoint(large) {
    display: block;
  }

  .swiper-slide {
    width: 64px;
    opacity: 0.6;
    cursor: pointer;

    @include breakpoint(xxxlarge) {
      width: 118px;
    }
  }

  .swiper-slide-active {
    opacity: 1;
  }
}

.swiper-button-prev, .swiper-button-next {
  background-size: 13px 22px;

  @include breakpoint(large) {
    background-size: 27px 44px;
  }

  @include breakpoint(xxlarge) {
    background-size: 27px 44px;
  }
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.gallery-carousel {
  .swiper-button-prev, .swiper-button-next {
    margin-top: rem-calc(16);

    @include breakpoint(large) {
      margin-top: 16px;
    }

    @include breakpoint(xxlarge) {
      margin-top: 64px;
    }
  }
}
