.image-left-text-right {
  margin-top: rem-calc(50);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(70);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(110);
  }

  img {
    width: 100%;
  }

  hr {
    @include hr;
    width: 32%;
    left: 0;
    top: rem-calc(40);
    right: auto;
    position: absolute;
    display: none;

    @include breakpoint(xlarge) {
      display: block;
      top: rem-calc(90);
      width: 35%;
    }

    @include breakpoint(xxlarge) {
      top: rem-calc(144);
      width: 31%;
    }
  }

  h1 {
    width: 100%;
    margin-bottom: 0;
    z-index: 1;
    position: relative;

    @include breakpoint(xlarge) {
      width: 80%;
      margin-top: rem-calc(18);
    }

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(21);
    }

  }

  h2 {
    @include p_bold;
  }

  figure {
    margin-top: rem-calc(-16);

    @include breakpoint(xlarge) {
      margin-top: rem-calc(-14);
    }

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(-24);
    }
  }
}
