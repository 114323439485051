.products {
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(80);

    @include breakpoint(large) {
        padding-top: rem-calc(100);
        padding-bottom: rem-calc(100);
    }

    @include breakpoint(xxlarge) {
        padding-top: rem-calc(190);
        padding-bottom: rem-calc(190);
    }

    h2 {
        text-align: center;
    }

    h2.line-after::after {
        background-color: #000;
    }

}

.productsItems__item {
    flex: 0 1 50%;

    @include breakpoint(large) {
        flex: 0 1 33.3333%;
    }

    a {
        text-decoration: none;
        color: #000;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        background-size: 0.5rem auto;
        background-position: right 2px bottom 4px;
        transition: opacity 0.2s ease-in-out;

        @include breakpoint(large) {
            background-size: 1rem auto;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    img {
        width: 100%;
        margin-bottom: 1rem;
    }

    h3 {
        text-decoration: none;
        margin: 0;
    }

    .format {
        width: 100%;
        margin: 0;
    }
}

.page-type-wallstone {
    h1.line-after {
        @include line-after;
        text-align: center;
    }

    h1.line-after::after {
        background-color: #000;
    }

    .productImages {
        position: relative;
    }

    .swiper-button-prev {

        @include breakpoint(large) {
            left: 20px;
        }

        @include breakpoint(xxlarge) {
            left: -29px;
        }
    }

    .swiper-button-next {

        @include breakpoint(large) {
            right: 20px;
        }

        @include breakpoint(xxlarge) {
            right: -29px;
        }
    }
}
