form {
  margin-top: rem-calc(50);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(100);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(196);
  }

  .hide {
    border-top: 4px solid $black;
    width: 80%;
    padding-top: 8px;
    margin-left: rem-calc(-15);
    padding-left: rem-calc(15);
    margin-bottom: rem-calc(-15);
    z-index: 2;
    position: relative;

    @include breakpoint(xlarge) {
      display: none;
    }
  }

  figure {
    text-align: center;
    max-width: 100%;
    margin: 0 auto rem-calc(24) auto;

    @include breakpoint(xlarge) {
      max-width: 100%;
      margin: 0;
    }

    img {
      margin: 0 auto;
    }
  }

  h2 {
    margin-bottom: rem-calc(8);

    @include breakpoint(xlarge) {
      margin-top: rem-calc(16);
      margin-bottom: rem-calc(26);
      margin-left: -35%;
    }

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(16);
      margin-left: -25%;
      margin-bottom: rem-calc(26);
    }

    &.animation {
      display: none;

      @include breakpoint(xlarge) {
        display: block;
      }
    }
  }

  input, textarea {
    border: 3px solid $black;
    @include swiss;
    font-size: rem-calc(17);
    line-height: 1;
    width: 100%;
    margin-bottom: rem-calc(8);
    padding: rem-calc(6) rem-calc(4);
    vertical-align: middle;

    @include breakpoint(large) {
      border: 4px solid $black;
      font-size: rem-calc(20);
      padding: rem-calc(9) rem-calc(8);
      margin-bottom: rem-calc(18);
    }

    @include breakpoint(xxlarge) {
      padding: rem-calc(12) rem-calc(8);
      margin-bottom: rem-calc(18);
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $medium-gray;
    }

    &[name="website"] {
      display: none;
    }
  }

  textarea {
    @include breakpoint(xlarge) {
      height: rem-calc(198);
      margin-bottom: rem-calc(10);
    }

    @include breakpoint(xxlarge) {
      height: rem-calc(218);
      margin-bottom: rem-calc(10);
    }
  }

  input[type=submit] {
    background-color: $white;
    display: none;
    transition: background-color 0.25s ease-out,color 0.25s ease-out;

    @include breakpoint(xlarge) {
      display: block;
      width: 75%;
      margin-left: 25%;
      margin-top: rem-calc(12);
      margin-bottom: rem-calc(16);
    }

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(86);
      margin-bottom: rem-calc(16);
    }

    &:last-child {
      margin-top: rem-calc(16);
      display: block;

      @include breakpoint(xlarge) {
        display: none;
      }
    }

    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  input[type=checkbox] {
    display: none;


    &[selected="selected"],
    &:checked {
     + label::before {
        background-color: $black;
      }
    }
  }

  label {
    @include swiss;
    display: inline-block;
    position: relative;
    margin-right: rem-calc(16);

    &::before {
      content: '';
      border: 3px solid $black;
      width: rem-calc(20);
      height: rem-calc(20);
      display: inline-block;
      margin-right: rem-calc(8);
      position: relative;
      top: 4px;

      @include breakpoint(xlarge) {
         border: 4px solid $black;
      }
    }
  }

  p {
    @include swiss;
    margin: 0;
    padding: 0;

    &::after {
      display: none;
    }
  }
}
