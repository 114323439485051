.partner, .break {
  position: relative;
  margin-top: rem-calc(50);

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(190);
  }

  &.white {
    color: $white;

    h2 {
      color: $white;

      &::after {
        background-color: $white;
      }
    }

    a {
      color: $white;
    }
  }

  &.black {
    color: $black;

    h2 {
      color: $black;

      &::after {
        background-color: $black;
      }
    }

    a {
      color: $black;
    }
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-around;
  }

  figure {
    img {
      width: 100%;
    }
  }

  h2 {
    text-align: center;
    width: 100%;
    margin: rem-calc(16) 0 0 0;

    @include breakpoint(large) {
      margin: rem-calc(24) 0 0 0;
    }

    // @include breakpoint(xxlarge) {
    //   margin-top: rem-calc(80);
    // }

    // &.line-after::after {
    //   margin: rem-calc(12) auto rem-calc(16) auto;

    //   @include breakpoint(xxlarge) {
    //     margin: rem-calc(32) auto rem-calc(16) auto;
    //   }
    // }
  }

  p {
    @include swiss;
    text-align: center;
    margin: 0;
    padding: 0 rem-calc(16);
  }


  ul {
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    justify-content: space-around;

    @include breakpoint(xxlarge) {
      width: 60%;
    }

    li {
      list-style-type: none;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 rem-calc(16) rem-calc(16) rem-calc(16);

      @include breakpoint(large) {
        flex: 0 0 33%;
        max-width: 33%;
      }

      @include breakpoint(xlarge) {
        flex: 0 0 20%;
        max-width: 20%;
      }

      a {
        display: block;
        text-align: center;

        img {
          @include breakpoint(large) {
            max-width: rem-calc(100);
          }
        }
      }
    }
  }
}

.partner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: rem-calc(32) 0 rem-calc(16) 0;

  @include breakpoint(large) {
    padding: rem-calc(32) 0 rem-calc(16) 0;
  }


  @include breakpoint(xxlarge) {
    padding: rem-calc(96) 0 rem-calc(96) 0;
  }
  
  .content {
    position: relative;
  }

  h2 {

  }

  ul {
    align-content: center;
    margin-top: rem-calc(-12);
    padding-bottom: rem-calc(32);

    @include breakpoint(large) {
      margin-top: 0;
    }

    //@include breakpoint(xlarge) {
    //  padding-bottom: 0;
    //}

    li {
      padding: rem-calc(8) rem-calc(32);

      @include breakpoint(large) {
        padding: rem-calc(16) rem-calc(62);
      }

      @include breakpoint(xlarge) {
        padding: 0 rem-calc(32);
      }

      &:first-child svg {
        width: 90%;
      }

      &:nth-child(3) svg {
        width: 80%;
      }

      &:last-child svg {
        width: 80%;
      }

      a {
        height: 100%;
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: space-around;
      }

      svg {
        display: block;
        width: 100%;
        max-height: 50px;

        @include breakpoint(xlarge) {
          max-height: 80px;
        }

        polyline,
        polygon,
        path,
        rect {
          fill: #ffffff;
          transition: fill 0.5s ease;
        }

        path.black,
        .black path {
          fill: #000000;
        }

        .grey {
          fill: $medium-gray;
        }
        
      }

      a:hover svg {
        path[fill="#0070ba"],
        polyline[fill="#0070ba"] {
          fill: #0070ba;
        }

        path[fill="#c8c8c8"] {
          fill: #c8c8c8;
        }

        path[fill="#1a1a18"],
        rect[fill="#1a1a18"],
        polygon[fill="#1a1a18"] {
          fill: #1a1a18;
        }

        path[fill="#005aa5"] {
          fill: #005aa5;
        }

        path[fill="#2299d6"] {
          fill: #2299d6;
        }

        path[fill="#0c0702"] {
          fill: #0c0702;
        }      

        path[fill="#fff"] {
          fill: #fff;
        }

        rect[fill="#4557a2"] {
          fill: #4557a2;
        }

        rect[fill="#e2000f"] {
          fill: #e2000f;
        }

        rect[fill="#e63130"] {
          fill: #e63130;
        }

        rect[fill="#2299d6"] {
          fill: #2299d6;
        }

        polygon[fill="#697179"] {
          fill: #697179;
        }

        polygon[fill="#c8c8c8"] {
          fill: #c8c8c8;
        }
      }
    }
  }
}

// .break {
//   .content {
//     display: flex;
//     flex-flow: row wrap;
//     align-content: center;
//     justify-content: space-around;
//   }

//   h2 {
//     margin-top: 0;
//   }
// }
