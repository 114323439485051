.image-collage-text.front {
  background-color: $light-gray;
  padding-top: rem-calc(32);
  padding-bottom: rem-calc(32);
  position: relative;

  @include breakpoint(large) {
    padding-top: rem-calc(64);
    padding-bottom: rem-calc(64);
  }

  @include breakpoint(xlarge) {
    padding-top: rem-calc(84);
    padding-bottom: rem-calc(84);
  }

  h1 {
    margin-bottom: rem-calc(4);
  }

  hr {
    @include breakpoint(xlarge) {
      top: 42vw;
    }

    @include breakpoint(xxxlarge) {
      top: rem-calc(490);
    }

    @include breakpoint(xxxxlarge) {
      width: 34%;
      top: rem-calc(520);
    }
  }

  .with-gallery .swiper-small {
    margin-top: rem-calc(8);

    @include breakpoint(large) {
      margin-top: 0;
    }
  }

  .content-split {
    display: flex;
    align-items: flex-end;
    flex-flow: row wrap;

    .first {
      padding-right: rem-calc(15);
      flex: 0 0 100%;
      max-width: 100%;

      @include breakpoint(large) {
        flex: 0 0 65%;
        max-width: 65%;
      }

      @include breakpoint(xlarge) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    .second {

      flex: 0 0 100%;
      max-width: 100%;

      @include breakpoint(large) {
        padding-left: rem-calc(15);
        flex: 0 0 35%;
        max-width: 35%;
      }

      @include breakpoint(xlarge) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    p {
      margin: 0 0 rem-calc(8) 0;

      @include breakpoint(large) {
        margin: 0;
      }
    }

    .button {
      margin-bottom: 3px;
      width: 100%;
    }
  }

}
