.image-collage-text {
  margin-top: rem-calc(50);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(70);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(110);
  }

  hr {
    display: none;
    @include hr;
    width: 28%;
    position: absolute;
    top: rem-calc(260);

    @include breakpoint(large) {
      display: block;
    }

    @include breakpoint(xlarge) {
      top: 35vw;
    }

    @include breakpoint(xxxlarge) {
      top: rem-calc(490);
    }

    @include breakpoint(xxxxlarge) {
      width: 34%;
      top: rem-calc(520);
    }

  }

  img {
    width: 100%;
  }

  h1 {
    position: relative;
    z-index: 2;

    @include breakpoint(large) {
      margin-top: rem-calc(-48);
    }

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(-80);
    }
  }

  .big p {
    @include p_bold;
  }

  .default {
    @include breakpoint(xlarge) {
      margin-top: rem-calc(48);
    }

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(80);
    }
  }
  
  .no-gallery {
    figure {
      display: none;

      @include breakpoint(large) {
        display: block;
      }
    }
  }

  .with-gallery {
    > figure {
      display: none;

      @include breakpoint(large) {
        display: block;
        vertical-align: top;
        width: percentage(410 / 630);
        margin-left: auto;
        margin-right: 0;
        margin-bottom: rem-calc(20);
      }

      @include breakpoint(xlarge) {
        margin-bottom: rem-calc(30);
      }
    }

    .swiper-small {
      width: 100%;
      margin-top: rem-calc(-32);

      @include breakpoint(large) {
        display: block;
        vertical-align: top;
        width: percentage(410 / 630);
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
}
