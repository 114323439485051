.menu-button {
  width: rem-calc(54);
  height: rem-calc(50);
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparentize($white, 0.5);
  padding: rem-calc(8) rem-calc(8) rem-calc(6) rem-calc(8);

  @include breakpoint(large) {
    display: none;
  }

  &.active {
    background-color: transparent;
    z-index: 2;
  }
}

.menu-button div,
.menu-button div::before,
.menu-button div::after {
  width: rem-calc(32);
  height: rem-calc(4);
  background-color: $black;
  transition: all 0.3s ease;
}

.menu-button div {
  width: rem-calc(32);
  height: rem-calc(4);
  display: inline-block;
  position: absolute;
  top: rem-calc(12);
  left: rem-calc(10);
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 10;

  @include breakpoint(large) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    background-color: $black;
  }

  &::before {
    top: -10px;
  }

  &::after {
    top: 10px;
  }

}

.menu-button.active div {
  background-color: transparent;

  &::before {
    transform: translateY(10px) rotate(45deg);
  }

  &::after {
    transform: translateY(-10px) rotate(-45deg);
  }
}

nav.header-nav {
  width: 100%;
  padding: 0 rem-calc(16);
  margin: 0 auto;
  //display: none;
  transform: translateX(-100%);
  transition: transform 0.25s ease;

  @include breakpoint(large) {
    transform: translateX(0%);
    max-width: 94.79%;
    padding-top: rem-calc(25);
    display: block;
    padding: rem-calc(25) 0 0 0;
  }

  @include breakpoint(xxxlarge) {
    padding-top: rem-calc(45);
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;

    li {
      list-style-type: none;
      flex: 0 0 100%;
      max-width: 100%;

      @include breakpoint(large) {
        flex: 0 0 rem-calc(96);
        max-width: rem-calc(96);
        margin: 0 rem-calc(7);
      }

      @include breakpoint(xlarge) {
        flex: 0 0 rem-calc(98);
        max-width: rem-calc(98);
        margin: 0 rem-calc(9);
      }

      &:first-child {
        @include breakpoint(large) {
          margin-left: 0;
        }
      }

      &.is-active {
        a {
          color: $black;

          &::before {
            background-color: $black;
          }
        }
      }

      a {
        color: $dark-gray;
        text-decoration: none;
        font-size: rem-calc(38);
        @include swiss;
        display: block;

        @include breakpoint(large) {
          color: $medium-gray;
          font-size: rem-calc(14);
          padding-top: rem-calc(9);
          position: relative;
        }

        @include breakpoint(xlarge) {
          font-size: rem-calc(14);
          padding-top: rem-calc(18);
          position: relative;
        }

        &::before {
          @include breakpoint(large) {
            content: '';
            display: block;
            background-color: $medium-gray;
            height: rem-calc(5);
            width: 100%;
            margin-left: 1px;
            position: absolute;
            top: 0;
          }

          @include breakpoint(xlarge) {
            height: rem-calc(8);
          }
        }

        &:hover {
          color: $black;

          &::before {
            background-color: $black;
          }
        }
      }
    }
  }
}

.menu-open {
  overflow: hidden;

  @include breakpoint(large) {
    overflow: auto;
  }

  .header-wrapper {
    z-index: 11;
  }

  nav {
    transform: translateX(0);
    //display: block;
    background-color: $white;
    height: 100vh;
    padding-top: 10vh;
    background-image: url(../images/bg-menu.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(large) {
      //padding-top: rem-calc(25);
      height: auto;
      background-image: none;
    }

    @include breakpoint(large) {
      padding-top: rem-calc(45);
    }
  }
}
