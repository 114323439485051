.image-text {
  margin-top: rem-calc(50);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(70);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(110);
  }

  article {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  h1.with-border {
    z-index: 2;
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;

    @include breakpoint(large) {
      flex: 0 0 40%;
      max-width: 40%;
    }

    @include breakpoint(xlarge) {
      flex: 0 0 54%;
      max-width: 54%;
    }
  }


  figure {
    margin: -12px 0 0 0;
    z-index: 1;
    flex: 0 0 100%;
    max-width: 100%;

    @include breakpoint(large) {
      margin: 0 0 0 -5%;
      flex: 0 0 65%;
      max-width: 65%;
    }

    @include breakpoint(xlarge) {
      margin: 0 0 0 -20%;
      flex: 0 0 66%;
      max-width: 66%;
    }

    img {
      width: 100%;
    }
  }

  .left {
    @include breakpoint(xlarge) {
      margin-top: rem-calc(15);
      flex: 0 0 percentage(630px / 1290px);
      max-width: percentage(630px / 1290px);
    }

    p {
      @include p_bold;
    }
  }

  .right {
    margin-top: rem-calc(-14);
    position: relative;

    @include breakpoint(xlarge) {
      margin-top: rem-calc(35);
      flex: 0 0 percentage(410px / 1290px);
      max-width: percentage(410px / 1290px);
    }

    &::before {
      width: 1px;
      height: 100%;
      content: '';
      background-color: #b2b2b2;
      display: inline-block;
      position: absolute;
      left: - percentage(126px / 420px);
    }

    p:first-child {
      @include breakpoint(xlarge) {
        margin-top: rem-calc(30);
      }
    }

    p:last-child {
      @include breakpoint(xlarge) {
        margin-bottom: 0;
      }
    }
  }

}
