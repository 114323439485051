.newsList {
    margin-top: rem-calc(50);

    @include breakpoint(medium) {
        //display: flex;
        //flex-flow: row wrap;
        //grid-template-columns: repeat(2, 1fr);
        //gap: 10px;
    }

    @include breakpoint(xlarge) {
        margin-top: rem-calc(70);
        //grid-template-columns: repeat(3, 1fr);
        //gap: 20px;
    }

    @include breakpoint(xxlarge) {
        margin-top: rem-calc(110);
    }

    .grid-sizer,
    .newsList__item {
        width: calc(50% - 10px);
        margin-bottom: 10px;

        @include breakpoint(xlarge) {
            width: calc(33.3333% - 20px);
            margin-bottom: 20px;
        }
    }

    .gutter-sizer {
        @include breakpoint(medium) {
            width: 10px;
        }

        @include breakpoint(xlarge) {
            width: 20px;
        }
    }

    &__item {
        background-color: $light-gray;
        margin-bottom: rem-calc(16);

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }

        &__teaser {
            padding: rem-calc(24);

            h3 {
                margin: 0;
            }
        }
        
        a {
            text-decoration: none;
            color: $black;
        }
    }
}
