.inspiration {
  margin-top: rem-calc(50);

  @include breakpoint(xlarge) {
    margin-top: rem-calc(70);
  }

  @include breakpoint(xxlarge) {
    margin-top: rem-calc(110);
  }
  
  .header {
    h2 {
      max-width: 40%;
      margin: 0;

      &.line-after::after {
        background-color: $black;
        margin-bottom: 0;
      }
    }

    hr {
      @include hr;
    }

    p {
      margin: 0;
      @include p_bold;
    }
  }

  .items, .swiper-wrapper {

    .column, .swiper-slide {
      margin-top: rem-calc(20);

      @include breakpoint(xlarge) {
        margin-top: rem-calc(40);
      }

      &:hover {
        img {
          opacity: 0.7;
        }
        figcaption {
          display: block;
        }
      }
    }
    
    a {
      text-decoration: none;
    }

    figure {
      margin-bottom: rem-calc(16);
      position: relative;

      @include breakpoint(xlarge) {
        margin-bottom: rem-calc(28);
      }
      
      a {
        display: block;
      }

      figcaption {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 5px solid $black;

        @include breakpoint(large) {
          border-top: 8px solid $black;
        }

        span {
          color: $black;
          @include swiss;
          line-height: rem-calc(160);
          font-size: rem-calc(140);
          margin-left: rem-calc(-10);
        }

        .button {
          position: absolute;
          bottom: rem-calc(-10);
          right: rem-calc(10);
        }
      }
    }

    h2, h3 {
      margin: 0;
      font-size: rem-calc(22);
      line-height: 1;
      color: $black;

      @include breakpoint(large) {
        font-size: rem-calc(24);
      }

      @include breakpoint(xlarge) {
        font-size: rem-calc(34);
      }
    }

    h2 {
      background-image: url('../images/icon-arrow-right-black.svg');
      background-position: right center;
      background-size: 20px auto;
      background-repeat: no-repeat;
      padding-right: rem-calc(16);
    }

    h3 {
      color: $medium-gray;
    }

    p {
      color: $black;
      margin: rem-calc(8) 0 0 0;
    }
  }
}

.slider-inspiration {
  background-color: $light-gray;
  padding-top: rem-calc(32);
  padding-bottom: rem-calc(32);

  @include breakpoint(large) {
    padding-top: rem-calc(64);
    padding-bottom: rem-calc(64);
  }

  @include breakpoint(xlarge) {
    padding-top: rem-calc(84);
    padding-bottom: rem-calc(84);
  }

  .header {
    h2 {
      text-align: center;
      max-width: 100%;
      margin-bottom: rem-calc(18);

      @include breakpoint(large) {
        margin-bottom: rem-calc(20);
      }

      @include breakpoint(xlarge) {
        margin-bottom: rem-calc(43);
      }
    }
  }

  .swiper-wrapper .swiper-slide {
    margin-top: 0;
  }

  .content {
    padding: 0 rem-calc(15);

    @include breakpoint(xxlarge) {
      padding: 0;
    }
  }
}
