h1 {
    @include h1;

    em {
        color: $medium-gray;
        font-style: normal;
    }
}

h2 {
    @include h2;

    &.line-after {
        @include line-after;
    }
}

h3 {
    @include h3;

    em {
        color: $medium-gray;
        font-style: normal;
    }
}

p {
    @include p;
}


figure {
    margin: 0;
}

.animation {
    @include breakpoint(xlarge) {
        z-index: 2;
    }
}

.wrapper {
    max-width: rem-calc(1920);
    margin: 0 auto;
    position: relative;
}

.button {
    @include button;
}

.with-border {
    @include with-border;
}

.top figure img {
    width: 100%;
}

.menu-spacing {
    height: 0;

    @include breakpoint(large) {
        height: rem-calc(64);
    }

    @include breakpoint(xxxlarge) {
        height: rem-calc(84);
    }
}

h2.pagetitle {
    color: $medium-gray;
    width: 100%;
    margin-bottom: 0;

    @include breakpoint(large) {
        display: none;
    }
}

h2.pagetitle + .with-border {
    margin-top: rem-calc(2);

    &::before {
        display: none;

        @include breakpoint(large) {
            display: block;
        }
    }
}

.page-type-about {
    h1.pagetitle {
        margin-top: rem-calc(40);

        @include breakpoint(large) {
            margin-top: rem-calc(80);
        }
    }
}

.page-type-simple {
    article {
        margin-top: rem-calc(64);
    }

    h3 {
        margin-bottom: 0;
    }

    h3 + p,
    h4 + p {
        margin-top: 0;
    }

    h4 {
        margin-bottom: 0;
    }

    p {
        a {
            color: $black;
        }
    }
}


.f-big {
    @include p_bold;
}

.f-grey {
    color: $medium-gray;
}
