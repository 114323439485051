/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/339ec7");

@font-face {
  font-family: 'Swiss721BT-Bold';
  src: url('webfonts/339EC7_0_0.eot');
  src: url('webfonts/339EC7_0_0.eot?#iefix') format('embedded-opentype'),
       url('webfonts/339EC7_0_0.woff2') format('woff2'),
       url('webfonts/339EC7_0_0.woff') format('woff'),
       url('webfonts/339EC7_0_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('webfonts/roboto-regular-webfont.woff2') format('woff2'),
      url('webfonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
