footer {

  h2 {
    @include h2;
    margin-top: 0;
  }

  .map {
    margin-top: rem-calc(50);

    @include breakpoint(xxlarge) {
      margin-top: rem-calc(100);
    }
  }

  .map .columns {
    padding: 0;

    @include breakpoint(xlarge) {
      padding: 0 rem-calc(15);
    }

    &:first-child {
      padding: 0 rem-calc(15);
    }


    //&:first-child  h3 {
    //  display: none;
    //
    //  @include breakpoint(xlarge) {
    //    display: block;
    //  }
    //}

    p {
      padding-left: 0;
      
       &::after {
         display: none;
       }

      &.big {
        @include p_bold;
      }

    }

    .default {
      p {
        @include p;
      }
    }
  }

  .bg {
    background-color: $light-gray;
    padding-top: rem-calc(68);
    margin-top: 0;
    background-image: url(../images/logo-small-footer.png);
    background-repeat: no-repeat;
    background-size: 31px 42px;
    background-position: calc(100% - 13px) 45px;
    display: flex;
    flex-flow: wrap row;

    @include breakpoint(large) {
      padding-top: rem-calc(20);
    }

    @include breakpoint(xxlarge) {
      background-size: 40px 56px;
      margin-top: rem-calc(110);
    }

    @include breakpoint(xxxlarge) {
      background-size: 50px 70px;
    }

    @include breakpoint(xxxxlarge) {
      background-size: 64px 86px;
    }

    &::after {
      content: ' ';
      background-image: url(../images/bg-footer.jpg);
      display: block;
      height: 20px;
      width: 100%;
    }
  }

  #map {
    min-height: rem-calc(350);

    @include breakpoint(xxlarge) {
      min-height: rem-calc(480);
    }
  }

  p {
    padding-left: rem-calc(25);
    @include swiss;
    font-size: rem-calc(13);
    margin-bottom: rem-calc(25);

    @include breakpoint(large) {
      font-size: rem-calc(15);
    }

    @include breakpoint(xlarge) {
      font-size: rem-calc(17);
    }

    &::after {
      margin-top: rem-calc(4);
      width: rem-calc(88);
      height: rem-calc(8);
      display: block;
      content: '';
      background-color: $black;
    }

    a {
      color: $black;
      text-decoration: none;
    }

  }

  nav {
    align-self: flex-start;
    margin: 0 auto rem-calc(16) rem-calc(25);

    @include breakpoint(large) {
      align-self: flex-end;
      margin: 0 percentage(316/1920) rem-calc(37) auto;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      
      a {
        @include swiss;
        font-size: rem-calc(15);
        color: $black;
        text-decoration: none;

        @include breakpoint(xlarge) {
          font-size: rem-calc(17);
        }
      }
    }
  }
}
